<template>
	<div class="mainTem">
		<div class="tableConTop">
			<el-row>
				<el-col :span="5" class="tableConTopLeft">
					<h3>
						<el-button type="primary" round icon="el-icon-back" size="mini" @click="goBack()">{{$t('i18nn_ca5a01a5adf20fe7')}}</el-button>

						<span class="tct_tit">{{$t('i18nn_3155418abb05309e')}}</span>
					</h3>
				</el-col>
				<el-col :span="19" class="tableConTopRig">
					<!-- <el-button type="success" circle icon="el-icon-refresh" size="small" @click="initData()"> -->
					</el-button>
				</el-col>
			</el-row>
		</div>

		<el-form ref="form" :rules="formRules" :model="form" inline label-width="210px" style="width: 100%;"
			v-loading="loading" :element-loading-text="$t('i18nn_1b4525c800280581')">

			<div style="margin-top:10px;">
				<!-- <div style=""> -->
				<!-- <div style="display: flex;align-items: center;"> -->
				<el-card style="">
					<div slot="header" class="">
						<h3>{{$t('i18nn_6c1ebb1581a6828e')}}</h3>
					</div>
					<div>
						<div style="">
							<!-- <el-form-item :label="$t('i18nn_35ec768075eb8f4e')" prop="">
										{{baseData.inWhCode}}
									</el-form-item> -->
							<el-descriptions title="" size="small">
								<el-descriptions-item :label="$t('i18nn_35ec768075eb8f4e')">{{baseData.inWhCode}}</el-descriptions-item>
							</el-descriptions>

							<el-table border :data="recordList" size="small" @selection-change="handleSelectionChange"
								@row-click="handleCurrentChange" :max-height="$store.state.frameConHeightWh3"
								style="width: 100%;">

								<el-table-column type="selection" fixed="left" align="center" width="55">
								</el-table-column>

								<!-- <el-table-column type="index" :index="$Utils.tableIndex(pagination)" fixed="left" width="50" align="center"
											:label="$t('Storage.tableColumn.no')"></el-table-column> -->

								<!-- <el-table-column prop="inWhCode" :label="$t('i18nn_559fed47b0e95407')" width="">
										</el-table-column> -->

								<el-table-column prop="ctnMark" :label="$t('i18nn_73fc38e67381ae73')" width="">
								</el-table-column>

								<el-table-column prop="trCtnCount" :label="$t('i18nn_5caaadc380029e3f')" width="160">
									<template slot-scope="scope">
										<el-input-number :disabled="scope.row.disabled" size="small"
											v-model="scope.row.trCtnCount" maxlength="">
										</el-input-number>
									</template>
								</el-table-column>

								<!-- <el-table-column prop="trPallte" :label="'托数(可用)'" width="160">
											<template slot-scope="scope">
												<el-input-number :disabled="scope.row.disabled" size="small" v-model="scope.row.trPallte" maxlength="">
												</el-input-number>
											</template>
										</el-table-column> -->

								<!-- <el-table-column prop="inWhRecord" label="已入库明细ID" width="">
										</el-table-column> -->

								<!-- <el-table-column prop="inWhCode" :label="$t('i18nn_559fed47b0e95407')" width="">
										</el-table-column> -->
								<!-- <el-table-column prop="ctnMark" :label="$t('i18nn_73fc38e67381ae73')" width="">
										</el-table-column> -->
								<!-- <el-table-column prop="ctnCount" :label="$t('i18nn_e7b838a996147e21')" width="">
										</el-table-column> -->
								<!-- <el-table-column prop="inWhPallte" :label="$t('i18nn_c8773d8d74202801')" width="">
								</el-table-column> -->
								<!-- 在库数（stock）   锁定数（lockTotal）   可用数（stock-lockTotal） -->
								<el-table-column prop="stockLockTotal" :label="$t('i18nn_51c7caf81e915eb4')" width="">
									<template slot-scope="scope">
										<strong
											class="red">{{parseFloat(scope.row.stock?scope.row.stock:0)-parseFloat(scope.row.lockTotal?scope.row.lockTotal:0)}}</strong>
									</template>
								</el-table-column>

								<el-table-column prop="stock" :label="$t('i18nn_48bc6e8b56cba243')" width="">
										</el-table-column>
										<el-table-column prop="lockTotal" :label="$t('i18nn_0ddc3b0b28d12b29')" width="">
										</el-table-column>


								<el-table-column prop="inWhTypeName" :label="$t('i18nn_643e08ba53f72f39')" width="">
								</el-table-column>


								<!-- <el-table-column prop="ctnUnit" :label="$t('i18nn_b6bf0a07fe26f74f')" width="">
										</el-table-column> -->

								<el-table-column prop="ctnL" min-width="120">
									<template slot="header">
										<div><span>{{$t('i18nn_53f9f6f694a5b0a8')}}</span>(L*W*H)</div>
									</template>
									<template slot-scope="scope">
										{{scope.row.ctnL}}*{{scope.row.ctnW}}*{{scope.row.ctnH}}
									</template>
								</el-table-column>

								<el-table-column prop="ctnWeight" :label="$t('i18nn_9e168db87b716866')" width="">
								</el-table-column>

								<el-table-column prop="goodsSku" width="">
									<template slot="header">
										<div><span>{{$t('i18nn_c5ef0b068e92e23d')}}</span>SKU</div>
									</template>
								</el-table-column>

								<!-- <el-table-column prop="ctnSkuCount" :label="$t('i18nn_85a10d3e5ea0e103')" width="">
										</el-table-column> -->

								<el-table-column prop="skuTotal" :label="$t('i18nn_bad53577db0da2d3')" width="">
								</el-table-column>

								<el-table-column prop="remark" :label="$t('i18nn_15b3627faddccb1d')" width="" min-width="200" show-overflow-tooltip>
									<template slot-scope="scope">
										<el-input size="small" type="textarea" :disabled="scope.row.disabled" v-model="scope.row.remark"  :maxlength="1000" show-word-limit >
										</el-input>
									</template>
								</el-table-column>

								<!-- <el-table-column :label="$t('Storage.tableColumn.operation')" width="120px"
											fixed="right" align="left">
											<template slot-scope="scope">
												<div>
													<el-button @click="delDet($event, scope.row,scope.$index)"
														type="danger" size="small" icon="el-icon-minus">
														{{$t("FormMsg.Delete")}}</el-button>
												</div>
											</template>
										</el-table-column> -->
							</el-table>
						</div>
					</div>
				</el-card>
				<!-- <div style="margin:0 10px">
							<el-button icon="el-icon-arrow-left" type="primary" @click="addRec()">{{$t('i18nn_c0246c55b9cac963')}}</el-button>
						</div> -->
				<!-- </div> -->
				<!-- </div> -->

				<!-- <div style="flex:1; width:50%;">
					<TransportInWhDetPageList ref="TransportInWhDetPageList"></TransportInWhDetPageList>
				</div> -->
			</div>

			<div style="margin-top:10px">
				<el-card style="">
					<div style="display: flex;">
						<div style="width: 580px;">


							<el-form-item :label="$t('i18nn_fd6620afef3fab05')" prop="" required>
								<whNoSelect ref="whNoSelect" :disabled="true" @changeData="changWhNo" size="''">
								</whNoSelect>
							</el-form-item>
							<el-form-item prop="" required>
								<template slot="label">
									<span><span>{{$t('i18nn_e1d76a34da86da92')}}</span>FBA</span>
								</template>
								<el-radio-group v-model="form.isFba">
									<el-radio :label="'0'">{{$t('i18nn_e76d885ae1c74d4f')}}</el-radio>
									<el-radio :label="'1'">{{$t('i18nn_02ccd2cf723f9272')}}</el-radio>
								</el-radio-group>
							</el-form-item>
							<el-form-item prop="" v-if="'1'==form.isFba">
								<template slot="label">
									<span>FBA<span>{{$t('i18nn_5acbec83efb27445')}}</span></span>
								</template>
								<el-input size="" clearable v-model="form.fbaAddrCode" style="width: 220px;">
									<el-button slot="append" type="primary" icon="el-icon-search"
										@click="searchFbaAddress()"></el-button>
								</el-input>
							</el-form-item>

							<el-form-item prop="" required>
								<template slot="label">
									<span>FBA<span>{{$t('i18nn_1272ee58ec6ee1ad')}}</span>/<span>{{$t('i18nn_7110aa855f2767a1')}}</span>/<span>{{$t('i18nn_559fed47b0e95407')}}</span></span>
								</template>
								
								<el-input type="text" clearable v-model="form.relationNo"
									:placeholder="$t('FormMsg.Please_Enter')"></el-input>
							</el-form-item>

							<el-form-item :label="$t('i18nn_e98a6f2582037336')" prop="" required>
								<el-radio-group v-model="form.trType">
									<el-radio v-for="item in selectOption.wh_transfer_Inwh_type" :key="item.code"
										:label="item.code">{{ $Utils.i18nCodeText(item) }}</el-radio>
								</el-radio-group>
							</el-form-item>
							<el-form-item :label="$t('i18nn_c301b10948889cb5')" prop="">
								<el-date-picker v-model="form.planOutDate" type="date" value-format="yyyy-MM-dd"
									:placeholder="$t('FormMsg.Select_date_time')"></el-date-picker>
							</el-form-item>

						</div>

						<div style="flex:1;">
							<el-form-item :label="$t('i18nn_e520453b3f22ad66')" prop="">
								<transferAddressSel ref="transferAddressSel" @changeData="changeTransferAddress">
								</transferAddressSel>
								<span style="padding-left: 10px;">
									<el-checkbox v-model="isSaveAddress">{{$t('i18nn_4ce9979bfb6576d9')}}</el-checkbox>
								</span>
							</el-form-item>
							<el-form-item :label="$t('i18nn_59764ba919e3d70d')" prop="" required v-if="isSaveAddress">
								<el-input type="text" clearable v-model="addressName"
									:placeholder="$t('FormMsg.Please_Enter')"></el-input>
							</el-form-item>
							<div style="border: 1px dashed #e5e5e5; padding: 10px 0;">
								<el-form-item :label="$t('i18nn_8758fd50c87d6c9c')" prop="" :required="isSaveAddress">
									<el-input clearable type="textarea" :rows="2" v-model="form.addr" :maxlength="1000" show-word-limit 
										:placeholder="$t('FormMsg.Please_Enter')" style="width: 220px;"></el-input>
								</el-form-item>
								<el-form-item :label="$t('i18nn_5a9fb0915ecea987')" prop="">
									<el-input type="text" clearable v-model="form.phone"
										:placeholder="$t('FormMsg.Please_Enter')">
									</el-input>
								</el-form-item>
								<el-form-item :label="$t('i18nn_e05b5d049b64b040')" prop="" :required="isSaveAddress">
									<el-input type="text" clearable v-model="form.city"
										:placeholder="$t('FormMsg.Please_Enter')">
									</el-input>
								</el-form-item>
								<el-form-item prop="" :required="isSaveAddress">
									<template slot="label">
										<span><span>{{$t('i18nn_f9514183570a10fe')}}</span>/<span>{{$t('i18nn_7cab79716236ebb0')}}</span></span>
									</template>
									
									<el-input type="text" clearable v-model="form.state"
										:placeholder="$t('FormMsg.Please_Enter')">
									</el-input>
								</el-form-item>
								<el-form-item :label="$t('i18nn_b166e4e8fe9513fa')" prop="" :required="isSaveAddress">
									<el-input type="text" clearable v-model="form.country"
										:placeholder="$t('FormMsg.Please_Enter')"></el-input>
								</el-form-item>
								<el-form-item :label="$t('i18nn_c4913ab43009b365')" prop="" :required="isSaveAddress">
									<el-input type="text" clearable v-model="form.postalCode"
										:placeholder="$t('FormMsg.Please_Enter')"></el-input>
								</el-form-item>
							</div>
						</div>
					</div>
				</el-card>

				<!-- "ctnMark": null, //"外箱标",
				"inWhCode": null, //"转运单号",
				"inWhRecord": null, //"入库明细ID",
				"trCtnCount": null, //"箱数",
				"trPallte": null, //"托数" -->

				<el-card style="margin-top: 10px;" :body-style="{padding:'20px'}">
					<div slot="header" class="">
						<span>{{$t('i18nn_7fba76c83eec6d09')}}</span>
					</div>
					<div>
						<div>
							<el-form-item :label="$t('i18nn_f5d43732e3f6cf4d')" prop="">
								<whFileUploadInner :width="'500px'" ref="whFileUploadInner"
									:openTime="FileUploadOpenTime" :relationId="fileRelationId"
									@success="FileUploadSuccess">
								</whFileUploadInner>
							</el-form-item>
						</div>
						<div>
							<el-form-item :label="$t('i18nn_15b3627faddccb1d')" prop="">
								<el-input type="textarea" :rows="3" placeholder="" v-model="form.remark" :maxlength="1000" show-word-limit 
									style="width: 500px;">
								</el-input>
							</el-form-item>
						</div>
					</div>
				</el-card>
			</div>
		</el-form>

		<div style="margin: 10px;">
			<el-button type="warning" icon="el-icon-tickets" @click="submitFormAction()" style="width: 200px;">{{$t('i18nn_e186babc2e9cadf4')}}</el-button>
		</div>

		<!--  附件上传 -->
		<!-- <whFileUpload :openTime="FileUploadOpenTime" :relationId="fileRelationId" @success="FileUploadSuccess">
		</whFileUpload> -->

		<!--查看图片-->
		<!-- <el-dialog :title="$t('FormMsg.picture')" append-to-body :close-on-click-modal="false"
			:visible.sync="dialogImgVisible" width="1000px" top="0">
			<div style="overflow: auto; width: 100%; height: 80%;"><img :src="imgUrlBigShow" width="auto"
					height="auto" /></div>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" plain @click="dialogImgVisible = false">{{ $t('FormMsg.Close') }}</el-button>
			</div>
		</el-dialog> -->

		<!--选择sku-->
		<!-- <el-dialog :title="$t('FormMsg.Select')" append-to-body :close-on-click-modal="false" :visible.sync="dialogSelVisible" width="1200px" top="0">
			<div style=""><WSkuInfo :isSel="true" :status="'1'" @selectRow="selWhGoodsData"></WSkuInfo></div>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" plain @click="dialogSelVisible = false">{{ $t('FormMsg.Close') }}</el-button>
			</div>
		</el-dialog> -->
	</div>
</template>
<script>
	import {
		getDicData
	} from '@/axios/common.js';
	// import TransshipmentImport from '@/components/StorageCenter/workOrder/workOrderTem/TransshipmentImport.vue';
	// import WSkuInfo from '@/components/StorageCenter/WSkuProduct/WSkuInfo.vue';
	// import HyQuillEditor from '@/components/Common/HyQuillEditor.vue';
	// import HyElWhUpLoad from '@/components/Common/HyElWhUpLoad.vue';
	import whNoSelect from '@/components/StorageCenter/components/whNoSelect.vue';
	import transferAddressSel from '@/components/StorageCenter/components/transferAddressSel.vue';
	// import whFileUpload from '@/components/StorageCenter/components/whFileUpload.vue';
	import whFileUploadInner from '@/components/StorageCenter/components/whFileUploadInner.vue';
	// import whFileUploadInner from '@/components/StorageCenter/components/whFileUploadInner.vue';
	// import HotTableTransportInWh from '@/components/StorageCenter/Transport/HotTableTransportInWh.vue';
	import TransportInWhDetPageList from '@/components/StorageCenter/Transport/TransportInWhDetPageList.vue';

	export default {
		// name: 'BuyerOrder',
		//meta信息seo用
		// metaInfo: {
		//   title: '互易天下-厂家共享平台-买家中心-收货账单', // set a title
		// },

		// props: {
		// 	// mobile:"",
		// 	editId: {
		// 		// default: function() {
		// 		//   return false;
		// 		// },
		// 		// type: Boolean
		// 	},
		// 	openTime: {
		// 		// default: function() {
		// 		//   return '';
		// 		// },
		// 		// type: String
		// 	}
		// },
		components: {
			// WSkuInfo,
			// HyElWhUpLoad,
			// HyQuillEditor,
			// TransshipmentImport
			TransportInWhDetPageList,
			whNoSelect,
			transferAddressSel,
			// whFileUpload,
			whFileUploadInner
			// HotTableTransportInWh
			// whFileUploadInner
		},
		data() {
			return {
				// UserInfo: this.$store.getters.getUserInfo,
				// dialogFormVisible: false,
				// dialogFormStatus: 0, //0-新增，1-修改
				// dialogFormMsg: '',
				editId: '',
				baseData: {},
				//导入excel
				// dialogUploadVisible: false,
				loadingExcel: false,
				fileExcel: '',
				// excelData: [],
				excelFileName: '',

				fileRelationId: '',
				FileUploadOpenTime: '',

				//图片放大
				// dialogImgVisible: false,
				// imgUrlBigShow: '',

				//打开SKU选择框
				// dialogSelVisible: false,
				// openRowData: {},
				// openRowIndex: {},
				multipleSelection: [],

				loading: false,

				loading_det: false,

				isSaveAddress: false,
				addressName: "",

				// isShowSkuCheck: false,
				recordList: [],
				form: {
					"whNo": null, //this.$t('i18nn_c944a6686d996ab3'),
					"isFba": '1', //"是否FBA",
					"fbaAddrCode": null, //"FBA仓库编号",
					"relationNo": null, //"FBA申请号/物流号/转运单号",
					"country": null, //this.$t('i18nn_b166e4e8fe9513fa'),
					"state": null, //"省/州",
					"city": null, //this.$t('i18nn_e05b5d049b64b040'),
					"postalCode": null, //this.$t('i18nn_c4913ab43009b365'),
					"addr": null, //this.$t('i18nn_8758fd50c87d6c9c'),
					"phone": null, //this.$t('i18nn_c2d9cc88561f8cdc'),
					"trType": null, //this.$t('i18nn_e98a6f2582037336'),
					"recordList": [
						// {
						// "ctnMark": null, //this.$t('i18nn_fa4d50bc26e39b26'),
						// "inWhCode": null, //this.$t('i18nn_559fed47b0e95407'),
						// "inWhRecord": null, //"入库明细ID",
						// "trCtnCount": null, //this.$t('i18nn_bdc361ba04506136'),
						// "trPallte": null, //this.$t('i18nn_c8773d8d74202801')
						// },
					],
				},

				formRules: {
					sendNo: [{
						required: true,
						message: this.$t('FormMsg.Please_Enter'),
						trigger: 'blur'
					}],
					recevieName: [{
						required: true,
						message: this.$t('FormMsg.Please_Enter'),
						trigger: 'blur'
					}],
					whNo: [{
						required: true,
						message: this.$t('FormMsg.Please_Enter'),
						trigger: 'blur'
					}],
					recevieMobile: [{
						required: true,
						message: this.$t('FormMsg.Please_Enter'),
						trigger: 'blur'
					}],
					address1: [{
						required: true,
						message: this.$t('FormMsg.Please_Enter'),
						trigger: 'blur'
					}],
					carTmsType: [{
						required: true,
						message: this.$t('FormMsg.Please_Enter'),
						trigger: 'change'
					}],
					postCode: [{
						required: true,
						message: this.$t('FormMsg.Please_Enter'),
						trigger: 'blur'
					}],
					country: [{
						required: true,
						message: this.$t('FormMsg.Please_Enter'),
						trigger: 'blur'
					}], //this.$t('i18nn_b166e4e8fe9513fa'),
					province: [{
						required: true,
						message: this.$t('FormMsg.Please_Enter'),
						trigger: 'blur'
					}], //"省/州",
					city: [{
						required: true,
						message: this.$t('FormMsg.Please_Enter'),
						trigger: 'blur'
					}], //this.$t('i18nn_e05b5d049b64b040'),
					// address1: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }], //this.$t('i18nn_8db45b615b72b7a8'),
					// carTmsType: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }], //this.$t('i18nn_5165f3ee9377af83'),
					carPlanDate: [{
						required: true,
						message: this.$t('FormMsg.Please_Enter'),
						trigger: 'blur'
					}] //this.$t('i18nn_99b88de52e382ca4'),
				},
				loading_load: false,
				selectOption: {
					wh_size_unit: [],
					wh_transfer_Inwh_type: [],
					wh_transfer_arrive_way: []
				},

				dialogFormStatus: 0, //0-新增，1-修改

				// isOnlyView: false,

			};
		},
		// watch: {
		// 	'$route.query': function(newVal, oldVal) {
		// 		console.log('$route.query', newVal);
		// 		// if(newVal){
		// 		// this.dialogFormVisible = true;
		// 		this.initData();
		// 		// }
		// 	}
		// },
		//创建时
		created() {
			// this.getPageData();
			this.initData();
		},
		//编译挂载前
		mounted() {
			// this.getDicData();
			//数据字典
			getDicData(['wh_transfer_Inwh_type'],
				(data)=>{
					this.selectOption.wh_transfer_Inwh_type = data['wh_transfer_Inwh_type'];
			});
		},
		methods: {
			initData() {
				// this.$nextTick(() => {
				// 	this.$refs.HotTableTransport.clear();
				// });
				this.multipleSelection = [];
				if (this.$route.query.id) {
					this.editId = this.$route.query.id;
					//不允许编辑
					// this.hasEdit = false;
					this.getPageBaseData(this.editId);

					// this.fileRelationId = this.editId;
				}
				this.openUploadFile();
				// this.FileUploadOpenTime = new Date().getTime();
				// else {
				// 	this.hasEdit = true;
				// }
			},
			goBack() {
				this.$router.push({
					name: 'TransportInWh'
				});
			},
			changWhNo(data) {
				console.log('changWhNo', data);
				this.form.whNo = data.code;
				// this.filterData.userId = data.userId;
				// this.initData();
			},
			changeTransferAddress(data) {
				console.log('changeTransferAddress', data);
				// this.form.whNo = data.code;
				// this.filterData.userId = data.userId;
				// this.initData();
				this.addressName = data.name;
				this.form.addr = data.address;
				this.form.city = data.city;
				this.form.state = data.state;
				this.form.country = data.country;
				this.form.postalCode = data.zipCode;
				
				// "name": this.addressName, //this.$t('i18nn_59764ba919e3d70d'),
				// "address": this.form.addr, //this.$t('i18nn_8758fd50c87d6c9c'),
				// "city": this.form.city, //this.$t('i18nn_e05b5d049b64b040'),
				// "state": this.form.state, //this.$t('i18nn_7cab79716236ebb0'),
				// "zipCode": this.form.postalCode, //this.$t('i18nn_c4913ab43009b365'),
				// "country": this.form.country, //"US"
			},
			//选择行
			handleCurrentChange(row, event, column) {
				// this.$refs.multipleTable.toggleRowSelection(row, true); //点击选中
			},
			//多选
			handleSelectionChange(val) {
				console.log(val);
				this.multipleSelection = val;

				this.recordList.map(item2 => {
					item2.disabled = true;
					this.multipleSelection.map(item => {
						if (item2.id == item.id) {
							item2.disabled = false;
						}
					});
				});

			},

			//添加的选择的数据
			// addRec() {
			// 	this.$nextTick(() => {
			// 		let selData = this.$refs.TransportInWhDetPageList.getSelData();
			// 		console.log("selData", selData);
			// 		if (selData.length <= 0) {
			// 			this.$message.warning(this.$t('i18nn_fc089dc894530f12'));
			// 			return;
			// 		}
			// 		//新增
			// 		let HasRepeat = false; //是否存在重复
			// 		let new_recordList = [];
			// 		let old_recordList = this.form.recordList;
			// 		selData.map(item => {
			// 			let hasIndex = old_recordList.findIndex(item2 => {
			// 				return item2.inWhRecord == item.id;
			// 			});
			// 			if (hasIndex > -1) { //存在
			// 				HasRepeat = true;
			// 			} else {
			// 				new_recordList.push({
			// 					"ctnMark": item.ctnMark, //this.$t('i18nn_fa4d50bc26e39b26'),
			// 					"inWhCode": item.inWhCode, //this.$t('i18nn_559fed47b0e95407'),
			// 					"inWhRecord": item.id, //"入库明细ID",
			// 					"trCtnCount": item.ctnCount, //this.$t('i18nn_bdc361ba04506136'),
			// 					"trPallte": item.inWhPallte, //this.$t('i18nn_c8773d8d74202801')
			// 				});
			// 			}
			// 		});
			// 		if (HasRepeat) {
			// 			this.$message.success(this.$t('i18nn_a8bcab50b1eccec0'));
			// 		}
			// 		this.form.recordList = this.form.recordList.concat(new_recordList);
			// 	});
			// },

			//新增
			// addDet() {
			// 	this.form.recordList.push({
			// 		"ctnMark": null, //this.$t('i18nn_8a43886ff356ed78'),
			// 		"ctnCount": null, //this.$t('i18nn_53fa7ff1ea383b2f'),
			// 		"goodsSku": null, //"箱内SKU",
			// 		"ctnSkuCount": null, //this.$t('i18nn_85a10d3e5ea0e103'),
			// 		"inWhPallte": null, //this.$t('i18nn_18c1c1a3ea76e9d3'),
			// 		"skuTotal": null, //this.$t('i18nn_bad53577db0da2d3'),
			// 		"ctnL": null, //this.$t('i18nn_70b5c883b227851b'),
			// 		"ctnW": null, //this.$t('i18nn_0a389c3a5e3938d3'),
			// 		"ctnH": null, //this.$t('i18nn_5c31dfdb8f4781ec'),
			// 		"ctnWeight": null, //this.$t('i18nn_91263f8fa1a94da9'),
			// 		"ctnUnit": null, //this.$t('i18nn_b6bf0a07fe26f74f')
			// 	});
			// },
			// 删除
			// delDet(event, row, index) {
			// 	event.stopPropagation();
			// 	this.form.recordList.splice(index, 1);
			// },
			// parsingSuccess(data){
			// 	this.formBase.sendDto = data;
			// },
			// changeAgentUser(val){
			// 	// this.filterData.agentUser = val;
			// 	// this.initData();
			// },
			//跳转页面
			// toPageUrl(name){
			//   this.$router.push({'name':name});
			// },
			//提交(先提交附件，回调之后提交新增或者编辑方法)
			submitFormAction() {
				this.$refs['form'].validate(valid => {
					if (valid) {
						this.$nextTick(() => {
							this.$refs.whFileUploadInner.submitExcelFormAction();
						});
					} else {
						console.log('error submit!!');
						this.$alert(this.$t('tips.checkSubmitData'), this.$t('tips.tipsTitle'), {
							type: 'warning'
							//confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
						});
						return false;
					}
				});
			},
			//打开附件上传
			openUploadFile() {
				// event.stopPropagation();
				// this.isShowFileUpload = true;
				// this.fileRelationId = this.editId;
				this.fileRelationId = '';//出库的RelationId
				this.FileUploadOpenTime = new Date().getTime();
			},
			//附件上传成功回调
			FileUploadSuccess(data) {
				console.log("FileUploadSuccess", data);
				// this.initData();
				this.form.fileList = data;

				// return;
				this.submitForm('form');
				// event.stopPropagation();
				// this.isShowFileUpload = true;
				// this.fileRelationId = row.id;
				// this.FileUploadOpenTime = new Date().getTime();
			},
			//查询FBA地址
			searchFbaAddress() {
				console.log('searchFbaAddress');
				if ('' == this.form.fbaAddrCode) {
					this.$message.warning("请输入FBA仓库号");
					return;
				}
				this.form.country = "";
				this.form.state = "";
				this.form.city = "";
				this.form.postalCode = "";
				this.form.addr = "";
				this.form.phone = "";
				
				this.loading = true;
				this.$http
					.get(this.$urlConfig.WhTransferQueryFba + '?fbaCode=' + this.form.fbaAddrCode, {})
					.then(({
						data
					}) => {
						console.log(this.$t('i18nn_776496ec446f21f9'));
						console.log(data);
						this.loading = false;
						if (200 == data.code && data.data) {
							// "country": null, //this.$t('i18nn_b166e4e8fe9513fa'),
							// "state": null, //"省/州",
							// "city": null, //this.$t('i18nn_e05b5d049b64b040'),
							// "postalCode": null, //this.$t('i18nn_c4913ab43009b365'),
							// "addr": null, //this.$t('i18nn_8758fd50c87d6c9c'),
							// "phone": null, //this.$t('i18nn_c2d9cc88561f8cdc'),
							let addrData = data.data;
							this.form.country = addrData.country;
							this.form.state = addrData.state;
							this.form.city = addrData.city;
							this.form.postalCode = addrData.zipCode;
							this.form.addr = addrData.address;
							this.form.phone = addrData.phone;

						} else {
							// if (!data.msg) {
							// 	data.msg = this.$t('i18nn_d74d0bd89431d6d5');
							// }
							// this.$message.warning(data.msg);
						}
					})
					.catch(error => {
						console.log(error);
						console.log(this.$t('tips.errorData'));
						this.loading = false;
						// this.$message.error('查询失败!');
					});
			},

			//打开新增编辑，弹窗
			// openDioalog(formParm, msg) {
			// 	// console.log(formParm);
			// 	this.dialogFormVisible = true;
			// 	let form = Object.assign({}, formParm);
			// 	console.log('form', form);
			// 	this.dialogFormMsg = msg;
			// 	// // 重置
			// 	this.resetForm('form');
			// 	if (null === formParm) {
			// 		//新增

			// 		// this.form.weightUnit = '1';
			// 		// this.form.volumeUnit = '1';
			// 		// this.form.whFeeType = '1';

			// 		this.dialogFormStatus = 0;
			// 		// form.subUserId = null;
			// 		// form.userSubUserId = null;
			// 		// form.state = true;
			// 		//浅拷贝、对象属性的合并
			// 		this.form = form;
			// 	} else {
			// 		//修改
			// 		this.dialogFormStatus = 1;
			// 		// form.state = form.state === '0' ? true : false;

			// 		//浅拷贝、对象属性的合并
			// 		this.form = form;
			// 	}

			// 	this.$forceUpdate();
			// 	// this.$alert('确定要确认通过该笔账单！', this.$t('tips.tipsTitle'), {
			// 	//       type: 'warning',
			// 	//       //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			// 	//     });
			// },
			// 富文本
			// onEditorChange(html) {
			// 	this.form.contentStr = html;
			// },
			//打开编辑
			// openEdit(event, row, index) {
			// 	event.stopPropagation();
			// 	this.openDioalog(row, this.$t('FormMsg.Edit'));
			// 	this.getPageDetData(row.id);
			// },
			//请求分页数据
			// getPageBaseData(id) {
			// 	// let _this = this;
			// 	this.loading = true;
			// 	this.$http
			// 		.get(this.$urlConfig.WhTransferOutEditQuery + '/' + id, {})
			// 		.then(({
			// 			data
			// 		}) => {
			// 			console.log('分页，请求成功');
			// 			console.log(data);
			// 			this.loading = false;
			// 			this.form = data.data;
			// 			// this.getPageDetData(this.editId);
			// 			this.$nextTick(() => {
			// 				this.$refs.whNoSelect.init(this.form.whNo);
			// 			});
			// 			// if (0 != data.data.status) {
			// 			// 	this.isOnlyView = true;
			// 			// }
			// 			// this.isShowSkuCheck = this.form.isShowSku == 1 ? true : false;
			// 			// this.form.isShowSku = this.form.isShowSku==1?true:false;
			// 			// this.$nextTick(() => {
			// 			// console.log('this.form.recordList', this.form.recordList);
			// 			// this.$refs.HotTableTransport.loadData(this.form.recordList);
			// 			// });
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			console.log('分页，请求失败');
			// 			this.loading = false;
			// 		});
			// },
			//请求分页数据
			getPageBaseData(id) {
				// let _this = this;
				this.loading = true;
				this.$http
					.get(this.$urlConfig.WhTransferEditQuery + '/' + id, {})
					.then(({
						data
					}) => {
						console.log('分页，请求成功');
						console.log(data);
						this.loading = false;
						this.baseData = data.data;
						this.recordList = this.baseData.recoreList.map(item => {
							item.trCtnCount = parseFloat(item.stock ? item.stock : 0) - parseFloat(item
								.lockTotal ? item.lockTotal : 0);
							item.trPallte = item.inWhPallte;
							item.disabled = true;
							return item;
						});
						this.form.whNo = this.baseData.whNo;
						this.$nextTick(() => {
							this.$refs.whNoSelect.init(this.form.whNo);
						});
						// "isFba": '1', //"是否FBA",
						// "fbaAddrCode": null, //"FBA仓库编号",
						// "country": null, //this.$t('i18nn_b166e4e8fe9513fa'),
						// "state": null, //"省/州",
						// "city": null, //this.$t('i18nn_e05b5d049b64b040'),
						// "postalCode": null, //this.$t('i18nn_c4913ab43009b365'),
						// "addr": null, //this.$t('i18nn_8758fd50c87d6c9c'),
						// "phone": null, //this.$t('i18nn_c2d9cc88561f8cdc'),
						this.form.isFba = this.baseData.isFba;
						this.form.country = this.baseData.country;
						this.form.state = this.baseData.state;
						this.form.city = this.baseData.city;
						this.form.postalCode = this.baseData.postalCode;
						this.form.addr = this.baseData.addr;
						this.form.phone = this.baseData.phone;
						// this.form = data.data;
						// // this.getPageDetData(this.editId);
						// this.$nextTick(() => {
						// 	this.$refs.whNoSelect.init(this.form.whNo);
						// });
						// if (0 != data.data.status) {
						// 	this.isOnlyView = true;
						// }
						// // this.isShowSkuCheck = this.form.isShowSku==1?true:false;
						// // this.form.isShowSku = this.form.isShowSku==1?true:false;
						// // this.$nextTick(() => {
						// console.log('this.form.recoreList', this.form.recoreList);
						// this.$refs.HotTableTransport.loadData(this.form.recoreList);
						// // });
						// this.changeIsShowSku(this.form.isShowSku)
					})
					.catch(error => {
						console.log(error);
						console.log('分页，请求失败');
						this.loading = false;
					});
			},
			//明细
			//请求分页数据
			// getPageDetData(id) {
			// 	// let _this = this;
			// 	this.loading = true;
			// 	this.$http
			// 		.get(this.$urlConfig.WhTransferDetQuery + '/' + id, {})
			// 		.then(({
			// 			data
			// 		}) => {
			// 			console.log('分页，请求成功');
			// 			console.log(data);
			// 			this.loading = false;
			// 			// this.form = data.data;
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			console.log('分页，请求失败');
			// 			this.loading = false;
			// 		});
			// },
			//打开选择数据
			// openSelData(event, row, index) {
			// 	event.stopPropagation();

			// 	this.dialogSelVisible = true;
			// 	this.openRowData = row;
			// 	this.openRowIndex = index;
			//  // this.$nextTick(() => {
			// 	//   this.$refs.hyUpLoadImg1.openFile();
			// 	// });
			// },
			// //选择数据后回调
			// selWhGoodsData(selRow) {
			// 	this.dialogSelVisible = false;
			// 	console.log('selRow', selRow);
			// 	let row = this.openRowData;
			// 	// row.putWhNo = selRow.putWhNo;
			// 	// row.plPlaceNo = selRow.placeNo;
			// 	row.goodsSku = selRow.goodsSku;
			// 	// row.sendAcount = 0;
			// 	// row.mySkuDto = selRow;

			// 	this.$set(this.form.recordList, this.openRowIndex, row);
			// },
			//查看图片
			// openBigImg(event, imgsrc) {
			// 	event.stopPropagation();
			// 	console.log('openBigImg', imgsrc);
			// 	this.dialogImgVisible = true;
			// 	this.imgUrlBigShow = imgsrc;
			// },
			// fileChange(e) {
			// 	// this.loading = true;
			// 	console.log(e);

			// 	console.log(this.$t('i18nn_f156a9536049f461'), this.$refs.file);
			// 	// this.excelData = [];
			// 	this.form.sendRecordDtoList = [];
			// 	(this.fileExcel = null), (this.excelFileName = '');
			// 	if (!this.$refs.file || !this.$refs.file.files) {
			// 		this.$alert(this.$t('module.upload.Unknown_file'), this.$t('tips.tipsTitle'), {
			// 			// //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			// 		});
			// 		return;
			// 	}
			// 	if (this.$refs.file.files[0]) {
			// 		// if (this.fileAccept.indexOf(this.$refs.file.files[0].type) > -1) {
			// 		if (this.$refs.file.files[0].size < 1024 * 1024 * 10) {
			// 			this.fileExcel = this.$refs.file.files[0];
			// 			this.excelFileName = this.fileExcel.name;
			// 			// console.log(this.$t('i18nn_f156a9536049f461'),this.$refs.file);
			// 			// this.uploadExcel();
			// 		} else {
			// 			this.$alert(this.$t('module.upload.uploaded_over') + '10M', this.$t('tips.tipsTitle'), {});
			// 		}
			// 		// } else {
			// 		//   this.$alert(this.$t(this.$t('i18nn_4dadf13ebce2167d')), this.$t('tips.tipsTitle'), {
			// 		//   });
			// 		// }
			// 	} else {
			// 		// this.$alert(this.$t('i18nn_c8e99e36cefd743a'), this.$t('tips.tipsTitle'), {
			// 		// });
			// 		console.log(this.$t('i18nn_c8e99e36cefd743a'), this.$refs.file);
			// 	}
			// },
			// clearFile(){
			// 	this.fileExcel = null;
			// 	this.excelFileName = '';
			// 	// this.excelFileName = "";
			// 	this.$nextTick(() => {
			// 		try {
			// 			this.$refs.file.value = '';
			// 		} catch (e) {
			// 			console.log(e);
			// 			//TODO handle the exception
			// 		}
			// 	});
			// },
			// openExcel() {
			// 	try {
			// 		this.$refs.file.value = '';
			// 	} catch (e) {
			// 		console.log(e);
			// 		//TODO handle the exception
			// 	}
			// 	// this.$refs.file.value = '';
			// 	// this.$refs.file.dispatchEvent(new MouseEvent('click'));
			// 	var comment = this.$refs.file;
			// 	if (document.all) {
			// 		// For IE
			// 		comment.click();
			// 	} else if (document.createEvent) {
			// 		// 在ff中要为a标签添加click事件，并且侦听该事件
			// 		var ev = document.createEvent('MouseEvents');
			// 		ev.initEvent('click', false, true);
			// 		comment.dispatchEvent(ev);
			// 	} else {
			// 		// this.$alert('打开上传文件有误，请联系客服', this.$t('tips.tipsTitle'), {
			// 		this.$alert(this.$t('module.upload.open_error_img'), this.$t('tips.tipsTitle'), {
			// 			// //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			// 		});
			// 	}
			// },
			//导入excel
			// uploadExcel() {
			// 	if (!this.fileExcel) {
			// 		this.$alert(this.$t('module.upload.Please_sel_file'), this.$t('tips.tipsTitle'), {});
			// 		return;
			// 	}

			// 	this.loadingExcel = true;

			// 	let file = this.fileExcel;
			// 	var formdata = new FormData();
			// 	formdata.append('file', file);

			// 	this.$http
			// 		.post(this.$urlConfig.WhShipmentsListExUpload, formdata, {
			// 			headers: {
			// 				'Content-Type': 'multipart/form-data'
			// 			}
			// 		})
			// 		.then(({ data }) => {
			// 			console.log(this.$t('i18nn_bc868e024b80d2e3'));
			// 			console.log(data);
			// 			this.loadingExcel = false;
			// 			if (200 == data.code && data.rows) {
			// 				// this.excelData = data.rows;

			// 				this.form.sendRecordDtoList = data.rows;

			// 				this.$forceUpdate();
			// 				this.clearFile();
			// 				// if('1'==data.data.flag){
			// 				//   this.excelData = data.data.data;
			// 				// } else {
			// 				//   this.$alert(data.data.errorMsg, this.$t('tips.tipsTitle'), {
			// 				//     type: 'warning',
			// 				//     //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			// 				//   });
			// 				// }
			// 			} else {
			// 				this.$alert(data.msg ? data.msg : this.$t('tips.submitError'), this.$t('tips.tipsTitle'), {
			// 					type: 'warning'
			// 					//confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			// 				});
			// 			}
			// 			// if (200 == data.code && data.data) {
			// 			//   if('1'==data.data.flag){
			// 			//     this.excelData = data.data.data;
			// 			//   } else {
			// 			//     this.$alert(data.data.errorMsg, this.$t('tips.tipsTitle'), {
			// 			//       type: 'warning',
			// 			//       //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			// 			//     });
			// 			//   }

			// 			// } else {
			// 			//   this.$alert(data.msg?data.msg:this.$t("tips.submitError"), this.$t('tips.tipsTitle'), {
			// 			//     type: 'warning',
			// 			//     //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			// 			//   });
			// 			// }
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			console.log(this.$t('tips.submitError'));
			// 			this.loadingExcel = false;
			// 			this.$alert(this.$t('tips.submitRequestError'), this.$t('tips.tipsTitle'), {
			// 				type: 'warning'
			// 				//confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			// 			});
			// 		});
			// },

			//上传回调
			// changeFileUrl(val) {
			// 	console.log('changeFileUrl', val);
			// 	let listUrl = val.map(item => {
			// 		// "filePath":"","fileName"：“”
			// 		return {
			// 			url: item.url,
			// 			fileName: item.name
			// 		};
			// 	});

			// 	this.form.attachments = listUrl;
			// },

			//提交信息
			submitForm(formName) {
				// this.$nextTick(() => {
				// 	try {
				// 		this.$refs.HyElWhUpLoad.submitUpload();
				// 	} catch (err) {
				// 		console.log(err);
				// 	}
				// });
				if(this.multipleSelection && this.multipleSelection.length<=0){
					this.$message.warning(this.$t('FormMsg.Select_long_goods_transport'));
					return;
				}
				// let recordList = this.$refs.HotTableTransport.getData();
				// console.log("recordList",recordList);
				// return;
				// console.log('formBase',this.formBase);
				let recordList = [];
				this.multipleSelection.forEach(item => {
					// item.inWhRecord = item.id;
					recordList.push({
						"ctnMark": item.ctnMark, //this.$t('i18nn_fa4d50bc26e39b26'),
						"inWhCode": item.inWhCode, //this.$t('i18nn_559fed47b0e95407'),
						"inWhRecord": item.id, //"入库明细ID",
						"trCtnCount": item.trCtnCount, //this.$t('i18nn_bdc361ba04506136'),
						"trStockSour":"10",//货源类型-转运
						"remark":item.remark,
						// "trPallte": item.inWhPallte, //this.$t('i18nn_c8773d8d74202801')
					});
				});
				this.form.recordList = recordList;
				console.log('form', this.form);

				// this.$refs[formName].validate(valid => {
				// 	if (valid) {
				let formData = Object.assign({}, this.form);
				// this.$nextTick(() => {
				// formData.recordList = this.$refs.HotTableTransport.getData();
				// });
				// formData.isShowSku = this.isShowSkuCheck ? '1' : '0';

				console.log('formData', formData);
				// formData.id = this.editId;
				// formData.whNo = this.form.whNo;
				// formData.sendDto = this.form;
				//浅拷贝、对象属性的合并
				// if (0 === this.dialogFormStatus) {
				// formData.id = null;
				// formData.userId = this.UserInfo.id;
				if (this.isSaveAddress && this.addressName) {
						this.saveAddress();
				}

				// if (this.editId) {
				// 	this.postData(this.$urlConfig.WhTransferOutEdit, formData, () => {
				// 		console.log('submitSuccess');
				// 		// this.$emit('submitSuccess');
				// 		// this.goBack();
				// 		this.$router.push({name:"TransportOutWh"});
				// 	});
				// } else {
				this.postData(this.$urlConfig.WhTransferOutAdd, formData, () => {
					console.log('submitSuccess');
					// this.$emit('submitSuccess');
					// this.goBack();
					this.$router.push({
						name: "TransportOutWh"
					});
				});
				// }


				// } else {
				// 	formData.userId = this.UserInfo.id;
				// 	this.postData(this.$urlConfig.WhWorkOrderAdd, formData);
				// }
				// 	} else {
				// 		console.log('error submit!!');
				// 		this.$alert(this.$t('tips.checkSubmitData'), this.$t('tips.tipsTitle'), {
				// 			type: 'warning'
				// 			//confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
				// 		});
				// 		return false;
				// 	}
				// });
			},
			saveAddress() {
				this.$nextTick(() => {
					let id = this.$refs.transferAddressSel.getId();
					let address_formData = {
						"id": id?id:null, //"数据ID",
						"name": this.addressName, //this.$t('i18nn_59764ba919e3d70d'),
						"address": this.form.addr, //this.$t('i18nn_8758fd50c87d6c9c'),
						"city": this.form.city, //this.$t('i18nn_e05b5d049b64b040'),
						"state": this.form.state, //this.$t('i18nn_7cab79716236ebb0'),
						"zipCode": this.form.postalCode, //this.$t('i18nn_c4913ab43009b365'),
						"country": this.form.country, //"US"
						// phone: this.form.phone, //"phone"
					};
					this.$refs.transferAddressSel.addData(address_formData);
				})
			},
			//重置输入框
			// resetForm(formName) {
			// 	console.log(formName);
			// 	// console.log(this.$refs[formName]);

			// 	this.$nextTick(() => {
			// 		if (this.$refs[formName]) {
			// 			this.$refs[formName].resetFields();
			// 			// if ('form' === formName) {
			// 			// 	this[formName]['packRecordList'] = [];
			// 			// }
			// 		} else {
			// 			console.log('this.$refs[formName]', this.$refs[formName]);
			// 		}
			// 	});
			// 	this.$nextTick(() => {
			// 		try {
			// 			this.$refs.HyElWhUpLoad.initData();
			// 			this.$refs.HyElWhUpLoad.clearFileList();
			// 		} catch (err) {
			// 			console.log(err);
			// 		}
			// 	});
			// 	this.fileList = [];
			// },

			//提交信息
			postData(url, formData, callback) {
				// HttpTypelet _this = this;
				this.loading = true;
				// this.loading_load = true;
				let HttpType = {};
				// if ('delete' == type) {
				// 	HttpType = this.$http.delete(url, formData);
				// } else {
				HttpType = this.$http.put(url, formData);
				// }
				HttpType.then(({
					data
				}) => {
					console.log(this.$t('i18nn_bc868e024b80d2e3'));
					console.log(data);
					this.loading = false;
					if (200 == data.code) {
						this.$alert(this.$t('tips.submitSuccess'), this.$t('tips.tipsTitle'), {
							type: 'success'
						});
						callback();
						// }
					} else {
						// if (!data.msg) {
						//   data.msg = this.$t('tips.submitError');
						// }
						this.$alert(data.msg ? data.msg : this.$t('tips.submitError'), this.$t('tips.tipsTitle'), {
							type: 'warning'
							//confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
						});
					}
				}).catch(error => {
					console.log(error);
					console.log(this.$t('tips.submitError'));
					this.loading = false;
					// this.loading_load = false;
					this.$alert(this.$t('tips.submitRequestError'), this.$t('tips.tipsTitle'), {
						type: 'warning'
						//confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
					});
				});
			},
			//查询数据字典
			// getDicData() {
			// 	// let _this = this;
			// 	// console.log(keyword);

			// 	// this.loading_load = true;
			// 	this.$http
			// 		.put(this.$urlConfig.HyDicQueryList, ['wh_transfer_Inwh_type', 'wh_transfer_arrive_way',
			// 			'wh_size_unit'
			// 		])
			// 		.then(({
			// 			data
			// 		}) => {
			// 			console.log('查询数据字典，请求成功');
			// 			console.log(data);
			// 			if (200 == data.code && data.data) {
			// 				// this.selectOption.wh_weight_unit = data.data['wh_weight_unit'];
			// 				// this.selectOption.wh_vol_unit = data.data['wh_vol_unit'];
			// 				// this.selectOption.wh_goods_fee_type = data.data['wh_goods_fee_type'];
			// 				// this.selectOption.wh_size_unit = data.data['wh_size_unit'];
			// 				this.selectOption.wh_transfer_Inwh_type = data.data['wh_transfer_Inwh_type'];
			// 				// this.selectOption.wh_transfer_arrive_way = data.data['wh_transfer_arrive_way'];
			// 			} else {
			// 				if (!data.msg) {
			// 					data.msg = this.$t('tips.queryErrorFailed');
			// 				}
			// 				this.$message.warning(data.msg);
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			console.log('查询数据字典接口，请求失败');
			// 			this.$message.error(this.$t('tips.queryRequestFailed'));
			// 		});
			// }
		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less"></style>
